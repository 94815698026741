import React from 'react'
import { Footer } from './components/Footer'
import Header from './components/Header'
import Banner01 from './img/about-jujhar.jpg'
import MBanner01 from './img/mabout-jujhar.jpg'
import Slider from "react-slick";
import Aboutus from './img/about-jujhar.png'
import { NavLink } from 'react-router-dom';
import Number from './Number';
import { Contactform } from './components/Contactform';

export const Jujhar = () => {
    var settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        effect:'fade',
        speed: 800,
        autoplaySpeed:8000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
  return (
    <>
        <div className='abouthead'>
        <Header pageref = "aboutus"/>
        </div>
        <section id='Aboutus' className='p-0'>
        <div className='aboutbanner'>
            <div className='container'>
        <img src={Banner01} alt='' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" className='desktop-show' />
        <img src={MBanner01} alt='' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" className='mobile-show' />
        </div>
        </div>
    </section>
    <section id='aboutjujhar' className='pb-0'>
        <div className='aboutus'>
            <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                      <div className='aboutcolumn jujhargroup'>
                      <div className='aboutleft' data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <div className='title  text-left'>
                          <h2>Overview</h2>
                          <div className='img mobile-show'>
            <img src={Aboutus} alt='' />
        </div>
                          <h3>Jujhar Group - Over 30 years of business excellence</h3>
                        </div>
                        <div className='aboutcont'>
                          <p>The Jujhar Group is a multidimensional conglomerate headquartered in Ludhiana, Punjab. Headed by Chairman S Gurdeep Singh, a pioneer of the Indian Transport industry, the Jujhar Group has an enviable market leadership position in its many verticals and has an impressive customer base in growing sectors namely Logistics, Entertainment, Broadband, Media, News Channel, Real Estate, Infrastructure and Hospitality.</p>
                          <div className="wrap">
                            <a href='https://www.jujhargroup.com/about-jujhar-group' className="btn-11" target='_blank'><span>Know more</span></a>
                        </div>                         
                </div>
                    </div>
                 <div className='aboutright' data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
        <div className='img desktop-show'>
            <img src={Aboutus} alt='' />
        </div>
                  </div>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>
        <Number />
        <Contactform />
        <Footer />
    </>
  )
}
