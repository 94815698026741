import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import Error from "./Error";
import '../src/css/stylesheet.css';
import '../src/font/stylesheet.css';
import '../src/css/header.css';
import '../src/css/footer.css';
import '../src/css/responsive.css';
import { Project_js_enclave } from "./Project-js-enclave";
import { Jujhar } from "./Jujhar";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <>
    <Router>
    <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Project_js_enclave}></Route>
        <Route exact path="/about-jujhar" component={Jujhar}></Route>
        <Route component={Error}></Route>
      </Switch>
      </Router>
    </>
  );
};

export default App;