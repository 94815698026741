import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css"
import Logo from '../img/js_enclave_logo_black.png';

export const Header = ({pageref}) => {
    useEffect(() => {
        Aos.init({duration: 1000});
        window.addEventListener('scroll', changeBackground);             
    
        return () => {
          window.removeEventListener('scroll', changeBackground);
        }
       }, []);
       const canonical = window.location.href; console.log(canonical);
       const [navbar, setActive] = useState("false");
          
            const handleToggle = () => {
              setActive(!navbar);
            };
      let pagelink = window.location.origin;
      pagelink = (pageref=='aboutus') ? pagelink : "";
      // alert(pagelink);
       const [header,setNavbar] = useState(false);
        const changeBackground = () => {
         if(window.scrollY >= 80) {
           setNavbar(true);
         } else {
           setNavbar(false);
         }
        };      
  return (
    <header>
      <div className={header ? 'header fixed' : 'header'}>
    <div className='container'>
      <div className='row'>
      <div className='col-4 d-flex align-items-center'>
      <div className='logo' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
             <Link to='/'><img src={Logo} alt='JS Enclave'/> </Link>
          </div>
        </div>
        <div className='col-8 a-center d-flex align-items-end justify-content-end p-0'>
        <div className='header-right'>
        <div className={navbar ? 'navbar' : 'navbar active'} >       
            <div className='menus'>
            <ul className='d-flex align-items-center justify-content-end'>
              <li className="wrapper"><NavLink to='/about-jujhar' className="pseudo-text-effect" data-after="Jujhar Group" exact activeClassName="active_class"><span>Jujhar Group</span></NavLink></li>
              <li className="wrapper"><a href={pagelink + '/#footer'} onClick={handleToggle} className="pseudo-text-effect" data-after="Contact Us" exact activeClassName="active_class"><span>Contact Us</span></a></li>
              </ul>
              <ul className='d-flex align-items-center toplinks'>
              <li className="wrapper"><a href={pagelink + '/#1'} onClick={handleToggle} className="pseudo-text-effect" data-after="Home" exact activeClassName="active_class"><span>Home</span></a></li>
              <li className="wrapper"><a href={pagelink + '/#the-complex'} onClick={handleToggle} className="pseudo-text-effect" data-after="Complex" exact activeClassName="active_class"><span>Complex</span></a></li>
              <li className="wrapper"><a href={pagelink + '/#the-amenities'} onClick={handleToggle} className="pseudo-text-effect" data-after="Amenities" exact activeClassName="active_class"><span>Amenities</span></a></li>
              <li className="wrapper"><a href={pagelink + '/#the-residences'} onClick={handleToggle} className="pseudo-text-effect" data-after="Residences"><span>Residences</span></a></li>
              <li className="wrapper"><a href={pagelink + '/#the-location'} onClick={handleToggle} className="pseudo-text-effect" data-after="Location"><span>Location</span></a></li>              
              </ul>              
            </div>
              <div className='menuslide mobile-show'>
              <button onClick={handleToggle} className="hemburgur"><span></span><span></span><span></span></button>
              </div>
          </div>
          </div>
        </div>
    </div>
    </div>
    </div>
  </header>
  )
}
export default Header;