import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import HomeIMG  from "./img/banner.jpg"
import amenitiesIMG  from "./img/amenities.jpg"
import residenceIMG  from "./img/residences.jpg"
import highlightIMG  from "./img/highlights.jpg"
import { Services } from './Services';
import { Link, NavLink } from 'react-router-dom';
import Jujharfile from './img/Jujhar-realty.pdf'
import Projectlogo from './img/jujhar-realty.png'
import { Footer } from './components/Footer';
import  Jslogo from './img/js_enclave_logo.png';
import { Contactform } from './components/Contactform';
import Header from './components/Header';


// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
  // require('./statics/fullpage.scrollHorizontally.min');
};

export const Js_enclave = () => (
  
      <ReactFullpage
    pluginWrapper = {pluginWrapper}
    navigation
    anchors={["1", "the-complex", "the-amenities", "the-residences", "the-location", "footer"]}
    //fullpage options
    // licenseKey = {'YOUR_KEY_HERE'}
    scrollingSpeed = {1500} /* Options here */
    scrollHorizontally = {true}  /* Because we are using the extension */
    scrollHorizontallyKey = {'YOUR KEY HERE'}

    render={({ state, fullpageApi }) => {    
      var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 800,
        autoplaySpeed:8000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
      return (
        <>        
        <ReactFullpage.Wrapper>   
          <div className="section">
            <div className='proheader'>
              <div className='container'>
                  <div className='row align-items-center'>                    
                      <div className='col-6'>
                      <div className='prologo'>
                                <NavLink to='/'><img src={Jslogo}  className="js_logo"/></NavLink>
                                </div>
                      </div>
                      <div className='col-6'>
                      <div className='prologo d-flex justify-content-end text-end'>
                                <a><img src={Projectlogo} /></a>
                                </div>
                      </div>
                     </div>
              </div>
            </div>
          <div className='projectscroll first-stn'>
                  <img src={HomeIMG} />
                  <div className='sectioncont'>
                  <h1>Redefining luxury living in Ludhiana </h1>
                  </div>
                  </div>
            <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>         
          <div className="section" id='complex'>
          <div className='projectscroll first-stn'>
                  <img src={highlightIMG} />
                  <div className='top-title'>
                    <h2>THE COMPLEX</h2>
                  </div>
                  <div className='projectscont'>
                  <div class="details">
	            <h4>JS ENCLAVE AT A GLANCE</h4> 
	            <p>	                  
              <span>LOCATED IN SUNDER NAGAR CHOWK</span>
              <span>SECURED GATED COMPLEX OVER 10 ACRES</span>
              <span>30 FT. WIDE ROAD WITH INTERLOCK TILES</span>
              <span>WELL-DEMARCATED PLOTS</span>
              <span>UNDERGROUND OPTICAL FIBER FITTING</span>
              <span>MULTI-TIER SECURITIES</span>
	            </p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          <div className="section" id='amenities'>
          <div className='projectscroll first-stn'>
                  <img src={amenitiesIMG} />
                  <div className='top-title'>
                    <h2>THE AMENITIES</h2>
                  </div>
                  <div className='projectscont'>
                  <div class="details">
                  <h4>THE ULTIMATE COMFORT AND CONVENIENCE</h4> 
	            <p>	                  
	                <span>IMMACULATED PARKS &amp; GARDENS</span>
                  <span>OPEN GYM FACILITIES</span>
                  <span>SENIOR CITIZEN AREA</span>
                  <span>KID’S PLAY AREA</span>
                  <span>YOGA &amp; MEDITATION</span>
	            </p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          <div className="section" id='amenities'>
          <div className='projectscroll first-stn'>
                  <img src={residenceIMG} />
                  <div className='top-title'>
                    <h2>THE RESIDENCES</h2>
                  </div>
                  <div className='projectscont'>
                  <div class="details">
                  <h4>PLOTS FOR GENERATIONS TO COME</h4> 
	            <p>	                  
	                <span>243 PRIME PLOTS</span>
	                <span>SIZE FROM 70 SQ.YDS. TO 172 SQ. YDS.</span>
	                <span>MOST OF PLOTS WITH GARDEN CLOSE BY</span>
	                <span>PROVISION FOR ELECTRICITY &amp; SEWAGE CONNECTION</span>
	                <span>TREE LINED STREET ROADS</span>
	            </p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          
          <div className="section" id='location'>
          <div className='projectscroll first-stn location'>
                  <div className='top-title'>
                    <h2>THE LOCATION</h2>
                  </div>
                  <div class="loc_details">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3423.4568259484936!2d75.93409801513559!3d30.90185268157851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDU0JzA2LjciTiA3NcKwNTYnMTAuNiJF!5e0!3m2!1sen!2sin!4v1679743134301!5m2!1sen!2sin" style={{border:0}} allowfullscreen=""></iframe>
  	              </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>

          <div className='section profooter jsfooter'>
          <Contactform />
          <Footer />
          </div>
        </ReactFullpage.Wrapper>
        </>
      );
    }}
  />
);
export default Js_enclave;