import React from 'react';
import Header from './components/Header';
import { Enquire } from './components/Enquire';
import Js_enclave from './Js_enclave';

export const Project_js_enclave = () => {
  return (
    <>
    <Js_enclave/> 
    <div className='effectheader'>
    <Header />
    </div>
    
</>
  )
}
export default Project_js_enclave
